import { Component, OnInit, TemplateRef, inject } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from '../../services/authenticate-service/authorization.service';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-clientconfig-report',
  templateUrl: './clientconfig-report.component.html',
  styleUrls: ['./clientconfig-report.component.css']
})






export class ClientconfigReportComponent {
    public reportcycleid:any;
    public selectedTIN:string = "";
    public tin:any;
    public eoc:any;
    public selectedEOC:string = "";
    public cfgNew:string = "ClientConfig";
    public cad:string = "ContractAdmin";
    public ccReportList:any;
    page = 1;
  	pageSize = 10;
	  collectionSize =   10;


    constructor(
      public authservice: AuthorizationService, 
      public localStorage:LocalStorageService,
      private storage_token: LocalStorageService,
      config: NgbModalConfig,
      private modalService: NgbModal,
      private toastr: ToastrService,
      private router: Router,
    ) {
      // customize default values of modals used by this component tree
      config.backdrop = 'static';
      config.keyboard = false;
    }
    
    titlemodel = {
      columns: [
     {label: 'STATE', id: 'STATE', datatype: 'text'},
     {label: 'TIN', id: 'TIN', datatype: 'text'},
     {label: 'PROVIDER NAME', id: 'PROVIDER_NAME', datatype: 'text'},
     {label: 'EOC ID', id: 'EOC_ID', datatype: 'text'},
     {label: 'REPORT CYCLE ID', id: 'RPT_CYCLE_ID', datatype: 'text'},
     {label: 'REPORT CONFIG ID', id: 'RPT_CONFIG_ID', datatype: 'text'},
     {label: 'CONFIG ID', id: 'CONFIG_ID', datatype: 'text'},
    ]
  }

    tinList:any = this.authservice.getTINSList();
    EOCList: any = this.authservice.getEOCList();

    alphanumeric_Only(event): boolean {

      var regex = new RegExp("^[a-zA-Z0-9]+$");
      var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
      if (!regex.test(key)) {
         event.preventDefault();
         return false;
      }
      return true;
    }

    SelectTIN(_value:string)
    {
      this.selectedTIN = _value;
    }
    SelectEOC(_value:string)
    {
      this.selectedEOC = _value;
    }

    

    SearchReports()
    {
      console.log("calling SearchReports()");
      // if(this.reportcycleid == undefined || this.reportcycleid=="" )
      //   {
      //     this.toastr.error(
      //       'Report Cycle ID: Required.'
      //     );
      //     return false;
      //   }

        // if(this.selectedTIN == undefined || this.selectedTIN=="" )
        //   {
        //     this.toastr.error(
        //       'TIN: Required.'
        //     );
        //     return false;
        //   }
        if((this.reportcycleid == undefined || this.reportcycleid=="") && 
        (this.selectedEOC == undefined || this.selectedEOC=="" ) && 
        (this.selectedTIN == undefined || this.selectedTIN=="" ))
        {
          this.toastr.error(
            'Report Cycle ID Or TIN Or EOC ID: Required.'
          );
          return false;

        }

          this.LoadData()
          return true;
    } 

    private LoadData()
    {
      this.authservice.SearchReportCycles( this.reportcycleid,this.selectedTIN,this.selectedEOC);
      setTimeout(() => 
        {
          this.ccReportList = this.authservice.GetReportCycleData();
          console.log(this.ccReportList);
          this.collectionSize = this.ccReportList.length;
          this.refreshReport();
        }, 7000 );
    }
  

    refreshReport() {
      this.ccReportList = this.authservice.GetReportCycleData();
      if(this.ccReportList != undefined)
      {
          this.ccReportList = this.ccReportList.map((item, i) => ({ id: i + 1, ...item })).slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize,
          );
        }
      } 
}
