import { Component } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { AuthorizationService } from 'src/app/services/authenticate-service/authorization.service';
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { selectAll } from '@syncfusion/ej2-base';
import { NgxSpinnerService } from 'ngx-spinner';
import { privateDecrypt } from 'crypto';
import { isNgTemplate } from '@angular/compiler';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.css']
})
export class ClientConfigComponent {
  [x: string]: any;
  public previousreportcycleid:any;
  public reportcycleid:any;
  public reporttype:any;
  public duedate:any;
  public clientid:any;
  public partnerValue:any;
  public selectedclientid:string="";
  public show_hide_div:boolean = false;
  public ptype:any;
  public selectedPtype:string = "";
  public ccRptdtlsList:any;
  public ccRptdtlsListupdated:any;
  isChecked = false;
  public selectedclientids:string="";
  public configResult:any;
  dtmodel: NgbDateStruct;
  page = 1;
  pageSize = 10;
  collectionSize =   10;
  selectedReportType = 0;
  public cltreportdtls:string="";
  public indexup:any;

  constructor(
    public authservice: AuthorizationService, 
    public localStorage:LocalStorageService,
    private storage_token: LocalStorageService,
    private toastr: ToastrService,
    private _parserFormatter: NgbDateParserFormatter,
    private router: Router,
    private spinner: NgxSpinnerService

  ) 
  {
     this.configResult = this.storage_token.get('Client_Config_Result');
    
  }
  
    
  ccRptmodel = {
    columns: [
   {label: 'CLIENT ID', id: 'CLIENT_ID', datatype: 'text'},
   {label: 'EOC ID', id: 'EOC_ID', datatype: 'text'},
   {label: 'ALGORITHM VERSION', id: 'ALGORITHM_VERSION', datatype: 'text'},
   {label: 'CONFIG VERSION', id: 'CONFIG_VERSION', datatype: 'text'},
  ]
}



editIndex: number | null = null;

edit(index: number) {
  this.editIndex = index;
}

save(index: number) {
  this.editIndex = null;
  this.indexup = this.ccRptdtlsList[index].$id;
  this.ccRptdtlsListupdated.forEach(item => { 
    if (item.$id === this.indexup) {
      item.algorithM_VERSION = this.ccRptdtlsList[index].algorithM_VERSION;
      item.confiG_VERSION = this.ccRptdtlsList[index].confiG_VERSION;
  
    }});


 

  this.ccRptdtlsList[index].edit=true;
}

cancel() {
  this.editIndex = null;
}
 
  ReporttypeList:any = this.authservice.getReportTypeList();
  clientidList: any = this.authservice.getClientIDList();
  PList:any = [{code:"Both", value:"Both"}, 
              {code:"Measurement Period", value:"Measurement Period"}, 
              {code:"Quarterly", value:"Quarterly"}];

  ngAfterViewInit()
  {
      
  }
  

  alphanumeric_DOT_Only(event): boolean {

    var regex = new RegExp("^[a-zA-Z0-9.]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
    return true;
  }

  CheckboxChanges(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isChecked = checkbox.checked;
    console.log('Checkbox clicked, current state:', this.isChecked);
  }

  LoadDueDate()
  {
    this.duedate = this._parserFormatter.format(this.dtmodel);
  }

  SelectClientId(_clientid:string)
    {
      this.selectedClientId = _clientid;
      this.storage_token.set('clientid', this.clientid);    
    }

    SelectPtype(_value:string)
    {
      this.selectedPtype = _value;
      if (_value == "Both")
        {
          this.selectedReportType=1;
        }
      else if (_value == "MeasurementPeriod")
        {
          this.selectedReportType=3;
        }
      else if (_value == "Quarterly")
        {
          this.selectedReportType=2;
        }
      else
        {
          this.selectedReportType=0;
        }
    }

    // selectAll = false;

    // toggleSelectAll() {
    //   this.clientidList.forEach(clientid => clientid.value = this.selectAll);
    // }
  
    // updateSelectAll() {
    //   this.selectAll = this.clientidList.every(clientid => clientid.value);
    // }
    // getSelectedOptions() {
    //   this.selectedValues= this.clientidList.filter(clientid => clientid.value).map(clientid => clientid.code);
    //   return this.selectedValues;
    // }


//   selectedValues: string[] = [];
//   selectAll =true;
//  isAllSelected =true;
//   isSelected(option: string): boolean {
//     return this.selectedValues.includes(option);
//   }

//   // isAllSelected(): boolean {
//   //   return this.selectedValues.length === this.clientidList.length;
//   // }

//   onCheckboxChange(event: Event, option: any) {
//     const checkbox = event.target as HTMLInputElement;
//     if (checkbox.checked) {
//       this.selectedValues.push(option.code);
//     } else {
//       this.selectedValues = this.selectedValues.filter(value => value !== option.code);
//     }
//     console.log('Selected values:', this.selectedValues);
//   }
  
//   toggleSelectAll(event: Event) {
//     const checkbox = event.target as HTMLInputElement;
//     // this.isAllSelected = !this.isAllSelected;
//     if (checkbox.checked) {
//       this.selectedValues = [...this.clientidList];
//       this.clientidList.forEach(clientid => clientid.value = this.selectAll);

//     } else {
//       this.selectedValues = [];
//     }
//     console.log('Selected values:', this.selectedValues);
//   }

selectedOptions = [];
  selectAll = false;

  toggleSelectAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.selectedOptions = [...this.clientidList];
    } else {
      this.selectedOptions = [];
    }
  }

  toggleOption(option: string) {
    const index = this.selectedOptions.indexOf(option);
    if (index > -1) {
      this.selectedOptions.splice(index, 1);
    } else {
      this.selectedOptions.push(option);
      
    }
    this.selectAll = this.selectedOptions.length === this.clientidList.length;
  }



    validateClientConfig()
    {
      if(this.reportcycleid == undefined || this.reportcycleid=="" )
        {
          this.toastr.error(
            'Report Cycle ID: Required.'
          );
          return false;
        }

        if(this.duedate == undefined || this.duedate =="" )
          {
            this.toastr.error(
              'Report Due Date: Required.'
            );
            return false;
          }
        return true;

        

      }
      
      getClientConfig()
      {
        console.log("calling getClientConfig()");
      
        if(this.reportcycleid== undefined || this.reportcycleid=="") 
          {
            this.toastr.error(
              'Report Cycle Id: Required.'
            );
            return false;
          }
          
        if (this.duedate == undefined || this.duedate=="") 
          {
            this.toastr.error(
              'Report Due Date: Required.'
            );
            return false;
          }
        if(this.selectedOptions == undefined || this.selectedOptions.length==0 )
        {
          this.toastr.error(
            'Client Id: Required.'
          );
          return false;

        }
        if (this.isChecked  && (this.selectedPtype == undefined || this.selectedPtype == ""))
          {
            this.toastr.error(
              'Report Type: Required.'
            );
            return false;
          }

          this.LoadData()
          return true;
      }

    private LoadData()
    {
      this.selectedclientids="";
      this.selectedOptions.forEach(item => {
        if(this.selectedclientids == undefined|| this.selectedclientids=="")
          {
            this.selectedclientids = item + ",";
          } 
          else 
          {
            this.selectedclientids = this.selectedclientids + item + ",";
          }
      });
      this.selectedclientids=this.selectedclientids.substring(0, this.selectedclientids.length - 1);
  
      this.spinner.show();
      this.authservice.SearchClientConfigData(this.duedate,this.selectedclientids,this.isChecked,this.selectedReportType );

      setTimeout(() => 
        {
          this.ccRptdtlsList = this.authservice.GetClientConfigData();
          this.ccRptdtlsListupdated = this.ccRptdtlsList;
          this.collectionSize = this.ccRptdtlsList.length;
          this.refreshReport();
          this.spinner.hide();
        }, 10000 );
        
    }

    refreshReport() 
    {
      this.ccRptdtlsList = this.ccRptdtlsListupdated;
      if(this.ccRptdtlsList != undefined)
      {
          this.ccRptdtlsList = this.ccRptdtlsList.map((item, i) => ({ id: i + 1, ...item })).slice(
            (this.page - 1) * this.pageSize,
            (this.page - 1) * this.pageSize + this.pageSize,
          );
        }
      } 

  sleep(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
  saveClientConfig()
  {
    if(this.validateClientConfig()===true)
    {
      // this.ccRptdtlsList = this.authservice.GetClientConfigData();
      this.spinner.show();
      this.cltreportdtls="";
      for (const item of this.ccRptdtlsListupdated)
        {
          if (this.cltreportdtls=="")
            this.cltreportdtls = item.clienT_ID + "," + item.eoC_ID + "," + item.algorithM_VERSION + "," + item.confiG_VERSION + "," + this.duedate + ";";
          else
            this.cltreportdtls = this.cltreportdtls + item.clienT_ID + "," + item.eoC_ID + "," + item.algorithM_VERSION + "," + item.confiG_VERSION + "," + this.duedate + ";";
        }
        //this.authservice.SaveReportDtls(item.clienT_ID, item.eoC_ID, item.algorithM_VERSION, item.confiG_VERSION, this.duedate);
        //this.cltreportdtls=this.cltreportdtls.substring(0, this.cltreportdtls.length - 1);
        this.authservice.SaveReportDtls(this.cltreportdtls, this.selectedReportType);
        this.sleep(1000);


      this.authservice.SaveClientConfig(this.duedate, this.reportcycleid, this.selectedclientids, this.isChecked,this.selectedReportType );
      this.toastr.success("Client Config: Request submitted Succesfully.");
      this.spinner.hide();
      setTimeout(() => 
        {

          if(this.storage_token.get('Client_Config_Result') === true)
          {
            this.toastr.success("Client Config: Your Request Processed Succesfully.");
            this.router.navigateByUrl("/reports?program=ClientConfigResult");
          }
          else
          {
            this.toastr.success("Client Config: Unable to Save.");
          }
         
        }
       , 130000 );
    }
    else
    { 
      this.toastr.error("Unable to submit.  Please provide all Required values in Client Config.");
      
    }
  }
}
