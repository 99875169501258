<div ngbAccordion #accordion= "ngbAccordion">
  <div ngbAccordionItem="one" [collapsed]="false">
  <h2 ngbAccordionHeader>
  <button ngbAccordionButton>Client Configuration</button>
  </h2>
      <div ngbAccordionCollapse>
         <div ngbAccordionBody>
            <div class="row">
              
              <div class="row mb-4">
                 
                     <div class="col-sm-12 col-md-6 col-lg-3">
                        <div class="form-group">
                            <label class="form-control-placeholder" for = "reportcycleid">Report Cycle ID *</label>
                            <input type="text" id="reportcycleid"
                             [(ngModel)]="reportcycleid" 
                             class="form-control"
                             (keypress)="alphanumeric_DOT_Only($event)"
                             />
                        </div>
                   </div>

                   <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <label class="form-control-placeholder" for="reportduedate"
                        >Report Due Date *</label
                      >
                      <div class="input-group">
                      <input
                        id="reportduedate"
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="reportduedate"
                        [(ngModel)] = "dtmodel"
                        ngbDatepicker
                        #reportduedate="ngbDatepicker"        
                      />
                      <button
                        class="btn btn-outline-secondary bi bi-calendar3"
                        (click)="reportduedate.toggle()"
                         (focus)="LoadDueDate()"
                         (paste)="LoadDueDate()"
                        type="button"
                      ></button>
                                              </div>
                               </div>
                  </div>

                  <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <label class="form-control-placeholder" for="clientid">Client ID *</label>
                      <div ngbDropdown>
                        <button type="button" class="form-control" id="clientid" style="text-align:left"  ngbDropdownToggle>
                          {{selectedClientId}} 
                        </button>
                        <div ngbDropdownMenu>
                          <div style="width:100%">
                                    <input
                                    class="form-control"
                                    type="text"
                                    name="search"
                                    [(ngModel)] ="clientid"
                                    autocomplete="off"
                                    placeholder="Search"
                                    />
                          </div>
                          <div class="form-check">
                          <button ngbDropdownItem *ngFor="let clientid of clientidList  | filter: clientid" 

                            value = {{clientid.code}}
                            (click)="SelectClientId(clientid.code)">
                            {{ clientid.value }}
                          </button>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div> -->
                  
                  <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <label class="form-control-placeholder" for="clientid">Client ID *</label> 
                      <div class="dropdown" ngbDropdown>
                        <button type="button" class="form-control" id="clientid" style="text-align:left"  ngbDropdownToggle>
                          {{ selectedValues.length ? selectedValues.join(', ') : 'Select options' }}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="clientid">
                          <div style="width:100%">
                            <input
                            class="form-control"
                            type="text"
                            name="search"
                            [(ngModel)] ="clientid"
                            autocomplete="off"
                            placeholder="Search"
                            />
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="selectAll" [(ngModel)]="selectAll" [checked]="isAllSelected()" (change)="toggleSelectAll($event)">Select All
                          </div>
                          <div *ngFor="let option of clientidList  | filter: option">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" [checked]="isSelected(option)" (change)="onCheckboxChange($event, option)"> {{ option }}
                            </div>
                          </div>
                        </div>s
                      </div>

                    </div>
                  </div> -->

                  <!-- <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <label class="form-control-placeholder" for="clientid">Client ID *</label> 
                      <div class="dropdown" ngbDropdown>
                        <button type="button" class="form-control" id="clientid" style="text-align:left"  ngbDropdownToggle>
                          {{ selectedValues.length ? selectedValues.join(', ') : 'Select ClientId' }}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="clientid">
                          <div style="width:100%">
                            <input
                            class="form-control"
                            type="text"
                            name="search"
                            [(ngModel)] ="clientid"
                            autocomplete="off"
                            placeholder="Search"
                            />
                          </div>
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" id="selectAll" [checked]="isAllSelected" [(ngModel)]="selectAll" (change)="toggleSelectAll($event)"> Select All
                          </div>
                          <div *ngFor="let option of clientidList  | filter: clientid">
                            <div class="form-check">
                            <input class="form-check-input" type="checkbox" [id]="option.code" [checked]="isSelected(option)" [(ngModel)]="option.code" (change)="onCheckboxChange($event, option)"> {{ option.code }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> -->

                  <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <label class="form-control-placeholder" for="clientid">Client ID *</label> 
                      <div ngbDropdown class="dropdown">
                      <button class="form-control" ngbDropdownToggle>
                        {{ selectedOptions.length ? (selectedOptions.length > 2 ?
                          this.selectedOptions.join(', ').substring(0, 14) + ',...' : selectedOptions.join(', ')) : 'Select ClientId' }}
                      </button>
                      
                      <div ngbDropdownMenu>
                        <div style="width:100%">
                          <input
                          class="form-control"
                          type="text"
                          name="search"
                          [(ngModel)] ="clientid"
                          autocomplete="off"
                          placeholder="Search"
                          />
                        </div>
                        <button class="dropdown-item" (click)="toggleSelectAll()">
                          <input type="checkbox" [checked]="selectAll"> Select All
                        </button>
                        <div *ngFor="let option of clientidList">
                          <button class="dropdown-item" (click)="toggleOption(option)">
                          <input type="checkbox" [checked]="selectedOptions.includes(option)"> {{ option }}
                          </button>
                        </div>
                  
                      </div>
                      </div>
                    </div>
                  </div>

                  
 



                  <div class="col-sm-12 col-md-6 col-lg-3">
                    <div class="form-group">
                      <!-- <label class="form-control-placeholder" for = "rerun">Re-Run</label> -->
                      <!-- <input type="checkbox" id="rerunid"
                          [(ngModel)]="item.checked" 
                          class="form-control" />  -->
                        <!-- <input type="checkbox" [(ngModel)]="item.checked">{{item.title}} -->

                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="rerun" [(ngModel)]="isChecked" 
                          (change)="CheckboxChanges($event)">
                          <label class="form-check-label" for="rerun">
                            Re-Run
                          </label>
                        </div>
                      </div>
                    </div>


                    <div class="col-sm-12 col-md-6 col-lg-3">
                      <div class="form-group" *ngIf="isChecked">
                        <label class="form-control-placeholder" for="ptype">Report Type *</label>
                        <div ngbDropdown >
                          <button type="button" class="form-control" id="ptype" style="text-align:left"   ngbDropdownToggle>
                            {{selectedPtype}}
                          </button>
                          <div ngbDropdownMenu>
                            <div style="width:100%">
                              <input
                                class="form-control"
                                type="text"
                                name="search"
                                [(ngModel)] ="ptype"
                                autocomplete="off"
                                placeholder="Search"
                              />
                            </div>
      
                            <button ngbDropdownItem *ngFor="let ptype of PList | filter: ptype" 
                              value = {{ptype.code}}
                              (click)="SelectPtype(ptype.code)">
                              {{ ptype.code }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  
            </div>

            </div>
              <button class="btn btn-sm btn-outline-primary me-2" style="background-color: #002060; color:#ffffff;"
                (click)="getClientConfig()"
              > Continue
              </button>
            </div>

            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>CLIENTI ID</th>
                  <th>EOC ID</th>
                  <th>ALGORITHM VERSION</th>
                  <th>CONFIG VERSION</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of ccRptdtlsList; let i = index">
                  <td>
                    {{ item.clienT_ID }}
                  </td>
                  <td>
                    {{ item.eoC_ID }}
                  </td>
                  <td>
                    <input *ngIf="editIndex === i" [(ngModel)]="item.algorithM_VERSION" class="form-control" />
                    <span *ngIf="editIndex !== i">{{ item.algorithM_VERSION }}</span>
                  </td>
                  <td>
                    <input *ngIf="editIndex === i" [(ngModel)]="item.confiG_VERSION" class="form-control" />
                    <span *ngIf="editIndex !== i">{{ item.confiG_VERSION }}</span>
                  </td>
                  <td>
                    <button *ngIf="editIndex !== i" (click)="edit(i)" class="btn btn-primary">Edit</button>
                    <button *ngIf="editIndex === i" (click)="save(i)" class="btn btn-success">Save</button>
                    <button *ngIf="editIndex === i" (click)="cancel()" class="btn btn-secondary">Cancel</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="d-flex justify-content-between p-2">
              <ngb-pagination
                [collectionSize]="collectionSize"
                [(page)]="page"
                [pageSize]="pageSize"
                (pageChange)="refreshReport()"
              >
              </ngb-pagination>
           
            </div>
          
            

            <button class="btn btn-sm btn-outline-primary me-2" style="background-color: #002060; color:#ffffff;"
                (click)="saveClientConfig()"
              > Submit
              </button>

            
      </div>
</div>
</div>


