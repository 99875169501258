<div class="container-fluid programs-container">
    <div class="row">
        <div class="programs-header">PROGRAMS</div>
        <ul class="nav-list">
            <li class="nav-list-item program-item" *ngFor="let program of this.getProgramList()">
                <a routerLink="/reports" [queryParams]="{program: program}">
                <img src="../../../assets/{{program}}.png" alt="episode" class="program-icon">
                <span>{{ this.authservice.getProgramFullName(program) }}</span>
                </a>
            </li> 
        </ul>
        <ul class="nav-list">
            <li class="nav-list-item program-item" *ngIf="this.getIscontractAdminUser()===true">
                <a routerLink="/reports" [queryParams]="{program: cad}">
                <img src="../../../assets/calendar.png" alt="contract admin" class="program-icon">
                <span>Contract Admin</span>
                </a>
            </li>
        </ul>
        <ul class="nav-list">
            <li class="nav-list-item program-item" *ngIf="this.getIsclientConfigUser()===true">
                <a routerLink="/reports" [queryParams]="{program: cfg}">
                <img src="../../../assets/ClientConfig.png" alt="client config" class="program-icon">
                <span>Search Report Cycles</span>
                </a>
            </li>
        </ul>
        <ul class="nav-list">
            <li class="nav-list-item program-item" *ngIf="this.getIsclientReportDetailUser()===true">
                <a routerLink="/reports" [queryParams]="{program: crpt}">
                <img src="../../../assets/ReportDetails.png" alt="report details" class="program-icon">
                <span>Report Details</span>
                </a>
            </li>
        </ul>
  </div>

</div>
